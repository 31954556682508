import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { ShowMessageComponent } from '../components/show-message/show-message.component';
import { LoaderService } from '../components/loader/loader.service';
import { SkeletonComponent } from '../components/skeleton/skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CarouselModule } from 'primeng/carousel';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImgUploadComponent } from '../components/img-upload/img-upload.component';
import { ImgCarouselExpandedComponent } from '../components/img-carousel-expanded/img-carousel-expanded.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipDirective } from '../directive/tooltip.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateRangePickerComponent } from '../components/date-range-picker/date-range-picker.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ComingSoonComponent } from '../components/coming-soon/coming-soon.component';
import { SearchPipe } from '../pipes/search.pipe';
import { CaptchaComponent } from '../components/captcha/captcha.component';
import { ReplaceSpacePipe } from '../pipes/replace-space.pipe';
import { ResizeTableDirective } from '../directive/resize-table/resize-table.directive';
import { GenericFilterComponent } from '../components/generic-filter/generic-filter.component';
import { MatSingleSelectSearchComponent } from '../components/mat-single-select-search/mat-single-select-search.component';
import { PascalCasePipe } from '../pipes/pascalcase.pipe';
import { ConfirmDialogueComponent } from '../components/confirm-dialogue/confirm-dialogue.component';
import { AlertContentComponent } from '../components/alert-content/alert-content.component';
import { MatMultipleSelectSearchComponent } from '../components/mat-multiple-select-search/mat-multiple-select-search.component';
@NgModule({
  declarations: [
    AlertContentComponent,
    ShowMessageComponent, 
    SkeletonComponent,
    ImgUploadComponent,
    ImgCarouselExpandedComponent, 
    TooltipDirective, 
    ResizeTableDirective,
    DateRangePickerComponent, 
    ComingSoonComponent,
    CaptchaComponent,
    SearchPipe,
    ReplaceSpacePipe,
    GenericFilterComponent,
    MatSingleSelectSearchComponent,
    PascalCasePipe,
    ConfirmDialogueComponent,
    MatMultipleSelectSearchComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    MatGridListModule,
    CarouselModule,
    DragDropModule,
    NgbModule,
    AngularSvgIconModule.forRoot(),
    SelectButtonModule,
    ReactiveFormsModule,
  ],
  exports: [
    ShowMessageComponent, TooltipDirective, ResizeTableDirective, SkeletonComponent, MaterialModule, PascalCasePipe,
    DateRangePickerComponent, SearchPipe, CaptchaComponent, ReplaceSpacePipe, ComingSoonComponent,GenericFilterComponent,
    MatSingleSelectSearchComponent, ConfirmDialogueComponent,AlertContentComponent, MatMultipleSelectSearchComponent
  ],
  providers: [LoaderService, SearchPipe,ReplaceSpacePipe]
})
export class SharedModule { }
