import { Component, DoCheck, HostBinding, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { LoginService } from 'src/app/account/http/login.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { NAVIGATION, OPS_TEAM, UTILITIES, VIRTUAL_ASSISTANT } from '../nav-config';
import { User } from 'src/app/core/models/User';
import { NotificationComponent } from 'src/app/main/settings/components/notification/notification.component';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoginPopupComponent } from 'src/app/account/components/login-popup/login-popup.component';
import { StateManageService } from 'src/app/core/services/state-manage.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as $ from 'jquery';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { SessionPopComponent } from './session-pop/session-pop.component';
import { DisplayAppService } from 'src/app/main/display-app/http/display-app.service';
import { CMSService } from 'src/app/core/cms/cms.service';
import { HttpErrorResponse } from '@angular/common/http';
const helper = new JwtHelperService();

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit, DoCheck {

  globalsearchtxt = "";
  isGlobalSearch: boolean = false;

  isSupplierPortal = false;
  isValidUser=false;
  
  isSwitchEnable = false;

  userName: string;
  userRole: string;
  userGroup: string = "";
  user: User;
  externalUser = false;

  menuActive: boolean;

  // currentTheme: string = 'light-theme';
  themeValue;

  isLoggedIn: boolean;
  // isLoggedIn1: string;
  inActiveDuration: number = 0;
  sessionInterval;

  details: any = [];
  initials;
  navConfigData;
  activeLinkIndex = -1;
  switchToolTip = "Switch to API Portal"
  combinedRole = "";
  isSupplier = false;

  categoryList = [];

  sblock = 'none';
  showMenu: any = false;

  notiList = []
  notificationStatus = "read";
  @ViewChild(NotificationComponent) notifComp: NotificationComponent;

  constructor(private dataShare: DataShareService, public loginService: LoginService,
    private storageService: StorageService, public overlayContainer: OverlayContainer,
    private serv: ApiInformationService, public router: Router, private dialog: MatDialog,
    private stateMgmtService: StateManageService,
    private _snackBar: MatSnackBar,
    public loader: LoaderService, public cmsService: CMSService,
    public _cookieService: CookieService, private mappingService: DisplayAppService
  ) {  }

  onCloseHamMenu() {
    this.showMenu = !this.showMenu;
  }

  ngDoCheck() {
    if (!this.router.url.includes('/web/search') && !this.router.url.includes('?from=globalsearch')) {
      if (this.globalsearchtxt.length > 0 && localStorage.getItem('globalsearchtxt') !== null) {
        // localStorage.removeItem('globalsearchtxt');
        this.clearsearch();
      }
    }
  }

  async ngOnInit() {
    const url = window.location.href;
    if ((window.location.href).includes('supplier')) {
      this.isSupplierPortal = true;
      this.switchToolTip = "Switch to API Portal"
    } else {
      this.isSupplierPortal = false;
      this.switchToolTip = "Switch to Supplier Portal"
    }

    let user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    
    this.isValidUser = user?.role_id !=='' ? true: false ;
     console.log("useretailss",user,this.isValidUser)
    
     if(user){
      if ((user.roleId.indexOf(4) > -1 || user.roleId.indexOf(17) > -1 || user.roleId.indexOf(18) > -1)){
        this.isSwitchEnable = true;
      }else{this.isSwitchEnable = false}
  
      console.log('test',user.roleId.indexOf(12))
  
      if(user.roleId.indexOf(16) > -1) {this.isSupplier = true};
    }

    this.dataShare.getGlobalSearchRefresh().subscribe(res => {
      if (res == 'true') {
        this.globalsearchtxt == "";
        this.isGlobalSearch = false;
        this.sblock = 'none';
      }
    })


    this.dataShare.getGlobalSearchTrigger().subscribe((res: any) => {
      // console.log("page header,",res, localStorage.getItem('globalsearch'))
      if (res == 'true' && localStorage.getItem('globalsearch') == "true") {
        // console.log('op',localStorage.getItem("globalsearchtxt"))
        this.isGlobalSearch = true;
        this.globalsearchtxt = localStorage.getItem("globalsearchtxt");
        localStorage.removeItem('globalsearch');
      }
    });

    this.dataShare.getTheme().subscribe((res: any) => {
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);

    });

    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navConfigData.indexOf(this.navConfigData.find(
        tab => tab.link === '.' + this.router.url));
    });

    
    this.getCategories();
    // this.navConfigData = NAVIGATION['PUBLIC'];
    this.navConfigData = (window.location.href).includes('supplier') ? NAVIGATION['SUPPLER_HOME']:NAVIGATION['PUBLIC'];
    let rawUser = localStorage.getItem("sessionUserInfo");
    this.user = JSON.parse(rawUser);

    let obj = {}
    this.isLoggedIn = this.loginService.isAuthenticated();

    if (this.isLoggedIn) {
      //get user name from session storage
      let rawUser = localStorage.getItem("sessionUserInfo");
      this.user = JSON.parse(rawUser);

      if (this.user.tokenUserInfo['nickname'].includes("@")) this.externalUser = true;

      console.log("after login data", this.user)
      this.userName = this.user.tokenUserInfo.givenName + " " + this.user.tokenUserInfo.familyName;
      this.initials = this.user.tokenUserInfo.givenName[0] + this.user.tokenUserInfo.familyName[0];

      if (this.user) {
        let us = JSON.parse(localStorage.getItem("user_det"));
        let finalUser = JSON.parse(localStorage.getItem("sessionUserInfo"));
        this.userName = this.user.tokenUserInfo.givenName + " " + this.user.tokenUserInfo.familyName;
        this.initials = this.user.tokenUserInfo.givenName[0] + this.user.tokenUserInfo.familyName[0];

        this.userGroup = localStorage.getItem('userGroup');

        finalUser.roleId = [];
        finalUser.role = []
        finalUser['id'] = us['logged_in_user_id'];
        us?.role_details?.forEach(role => {
          finalUser.roleId.push(role.role_id);
          finalUser.role.push(role.role_name);
        });

        this.userRole = finalUser.role;
        finalUser.name = us.first_name + ' ' + us.last_name;
        finalUser.email = us.email_id;
        localStorage.setItem("sessionUserInfo", JSON.stringify(finalUser));
        localStorage.setItem('user_id', us["logged_in_user_id"]);
        localStorage.setItem('user_name', us.name);

        this.combinedRole = "";
        if (this.userRole.length > 1) {
          for (let i = 0; i < this.userRole.length; i++) {
            if (i != this.userRole.length - 1)
              this.combinedRole += this.userRole[i] + ", ";
            else this.combinedRole += this.userRole[i];
          }
        }
        else this.combinedRole = this.userRole[0]
        this.cmsService.getVirtualAssistanceData()
        .pipe(
          map(res => {
            this.setRole(finalUser, res);
          }))
        .subscribe();
      }
      if(!this.router.url.includes('web/supplier')) {
      this.getPreferences();
      this.getNotificationStatus();
      }
    }

    this.dataShare.getSideBarStatus().subscribe((data) => {
      this.menuActive = data;
    });


    setInterval(() => {

      // let isLoggedIn = this.loginService.isAuthenticated(); // early code
      this.isLoggedIn = this.loginService.isAuthenticated();
      if (this.isLoggedIn) {
        let myRawToken = this._cookieService.get('accessToken') !== null ? this._cookieService.get('accessToken') : undefined;
        const isExpired = helper.isTokenExpired(myRawToken);

        // console.log("isExpired", isExpired)
        let sessionTime = Math.abs(new Date().getTime() - new Date(helper.getTokenExpirationDate(myRawToken)).getTime()) / 60000
        if (!isExpired && Math.floor(sessionTime) % 2 == 0) {
          // console.log(sessionTime)
        }
        //  || sessionTime < 55 -> for testing
        if (isExpired) {
          // console.log(sessionStorage.getItem("timeoutfun"))
          if (!sessionStorage.getItem("timeoutfun"))
            this.refreshSession();
          // this.logh();

        }
      } else {
        if (localStorage.getItem("loginFlag") == 'block1') {
          localStorage.setItem("loginFlag", "block2")
          this.ngOnInit();
          // this.reloadCurrentRoute(); //suspicious
        }
      }
    }, 6000);

    this.sessionInterval = setInterval(() => {
      if (this.isLoggedIn) {
        this.inActiveDuration += 1;
        // console.log(this.inActiveDuration);

        // set inactivity threshold, currently 60
        if (this.inActiveDuration > 60) {
          this.logout();
        }
      }
    }, 60000); // checks every 1 min
  }

  sessionExpired() {
    clearInterval(this.sessionInterval);
    this._cookieService.deleteAll();
    this.loginService.logout();
    this.storageService.clearFullStorage();
    localStorage.clear();

    const dialogRef = this.dialog.open(SessionPopComponent, {
      width: '450px',
      disableClose: true
    });
  }

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  onMouseEvent(event: MouseEvent) {
    this.inActiveDuration = 0;
  }
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.inActiveDuration = 0;
  }
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onTouchEvent(event: TouchEvent) {
    this.inActiveDuration = 0;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {
      skipLocationChange: true
    }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getCategories() {
    this.serv.getCarouselData().subscribe(res => {
      this.categoryList = res.api_category_list;
      this.categoryList = this.categoryList.sort((a, b) => {
        if (a.category_order > b.category_order) return 1;
        else if (a.category_order < b.category_order) return -1;
        else return 0;
      });
    })
  }

  goToCat(category) {
    this.clearcatelogfilter();
    this.stateMgmtService.setState({
      action: "category",
      value: category
    });
    this.router.navigate(['/web/api/api-list']);
  }

  getNotificationStatus() {
    let user = JSON.parse(localStorage.getItem("sessionUserInfo"))
    let reqObj = {
      action: "get_list",
      email_id: user.tokenUserInfo.email,
    }
    this.serv.getNotifications(reqObj).subscribe(res => {

      if (res.notification_list && res.notification_list.length > 0) {
        this.notiList = res.notification_list;
        let temp = res.notification_list.filter(s => s.mark_read == 0);

        if (temp.length > 0) this.notificationStatus = 'unread';
        else this.notificationStatus = 'read';
      }
    });
  }

  menuAction(action) {
    //console.log("action",action)
    if (action == 'open') this.notifComp.ngOnInit();
    this.getNotificationStatus();
  }

  activateDigitalHubDropdown(digitalHubData) {
    if (!!digitalHubData && digitalHubData.length) {
      const idx = digitalHubData.findIndex(d => d.title === 'Virtual Assistant');
      if (idx !== -1) {
        const index = this.navConfigData.findIndex(n => n.label === 'Digital Hub');
        const idxx = this.navConfigData[index].children.findIndex(c => c.label === VIRTUAL_ASSISTANT.label)
        if (index !== -1) {
          if (digitalHubData[idx].status_id === 1) {
            if (idxx === -1) {
              this.navConfigData[index].children.push(VIRTUAL_ASSISTANT);            
            }
          } else {
            this.navConfigData[index].children = this.navConfigData[index].children.filter(c => c.label !== VIRTUAL_ASSISTANT.label);            
          }
        }
      }
    }
  }

  async setRole(user: User, digitalHubData) {
    // console.log('login role', user, user.roleId)

    if (user.roleId.length > 0) {
      if ((window.location.href).includes('supplier') &&  (user.roleId.indexOf(16) > -1)) this.navConfigData = NAVIGATION['SUPPLIER_PORTAL'];

      else if ((window.location.href).includes('supplier') && ((user.roleId.indexOf(4) > -1) ||(user.roleId.indexOf(18) > -1))) this.navConfigData = NAVIGATION['SUPPLIER_PORTAL_ADMIN'];
      else if ((window.location.href).includes('supplier') &&(user.roleId.indexOf(17) > -1)) this.navConfigData = NAVIGATION['SUPPLIER_PORTAL_ECOMMERCE'];
      // external user
      else if (this.externalUser) this.navConfigData = NAVIGATION['DEFAULT'];
      // admin
      else if (user.roleId.indexOf(4) > -1) {
        this.navConfigData = NAVIGATION['ADMIN'];
        this.activateDigitalHubDropdown(digitalHubData);
      }
      // (IS Team Lead X api owner) or (IS Developer X api owner)
      else if ((user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1) && user.roleId.indexOf(1) > -1) {
        this.navConfigData = NAVIGATION['API_OWNER_TEAM_LEAD_DEVELOPER'];
        this.activateDigitalHubDropdown(digitalHubData);
      }
      // IS Team Lead/IS Developer for exchange apps
      else if ((user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1)) {
        this.navConfigData = NAVIGATION['IS_TEAM_LEAD_DEVELOPER'];
        this.activateDigitalHubDropdown(digitalHubData);
      }
      // api owner
      else if (user.roleId.indexOf(1) > -1) {
        this.navConfigData = NAVIGATION['APIOWNER'];
        this.activateDigitalHubDropdown(digitalHubData);
      }
      else {
        this.navConfigData = NAVIGATION['CONSUMER'];
        this.activateDigitalHubDropdown(digitalHubData);
        // const index = this.navConfigData.findIndex(n => n.label === 'Digital Hub');
        // if (index > -1) {
        //   this.navConfigData[index].children.push(UTILITIES);
        // }
      }
    }
    else if (user.roleId.length == 0) this.navConfigData = NAVIGATION['DEFAULT'];

    const index = this.navConfigData.findIndex(n => n.label === 'Digital Hub');
    //  roles: IS Platform Team - 21, IS Platform Admin - 22, Ops Admin - 23, Ops Team Operators - 24, Ops Team Readonly - 25
    if (user.roleId.indexOf(22) > -1 || user.roleId.indexOf(21) > -1 || user.roleId.indexOf(4) > -1 
    || user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1 || user.roleId.indexOf(23) > -1 || 
    user.roleId.indexOf(24) > -1 || user.roleId.indexOf(25) > -1) {
      if (index > -1) {
        const idx = this.navConfigData[index].children.findIndex(n => n.label === 'Utilities');
        if (idx === -1) {
          this.navConfigData[index].children.push(UTILITIES);
        }
        
      }
    }

    if (user.roleId.indexOf(23) > -1 || user.roleId.indexOf(4) > -1) {
      const idx = this.navConfigData[index].children.findIndex(n => n.label === 'Operations Dashboard');
        if (idx === -1) {
          this.navConfigData[index].children.push(OPS_TEAM);
      }
    }
  }

  // setTheme() {
  //   if (this.currentTheme == 'light-theme') {
  //     this.currentTheme = 'dark-theme';
  //   }
  //   else {
  //     this.currentTheme = 'light-theme';
  //   }
  //   this.dataShare.setTheme(this.currentTheme);
  // }

  switch () {

    console.log('klshdj');
    this.isSupplierPortal ? window.location.href = '/web/home' : window.location.href = '/web/supplier';
  }

  toogleSideBar() {
    this.dataShare.setSideBarStatus(true);
  }

  doRegister(): void {
    if (this.isLoggedIn) {
      this.router.navigate(['/register']);
    } else {
      localStorage.setItem('requestedLogin', 'true');
      localStorage.setItem('requestedRegister', 'true');
      window.location.href =
        `${environment.oauth2.loginUrl}?response_type=${environment.oauth2.responseType}&client_id=${environment.oauth2.clientId}&redirect_uri=${environment.oauth2.redirectUrl}&state=1234&scope=${environment.oauth2.scope}&idp_identifier=AzureAD`;
    }
  }


  goToSettings() {
    this.router.navigate(['/web/settings/preference']);
  }

  doLogin(): void {
    if (this.isLoggedIn) {
      // this.router.navigate(['/register']);
    } else {
      this.dialog.open(LoginPopupComponent, {
        panelClass: 'login-dialog',
        disableClose: true
      })
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.dataShare.setSideBarStatus(this.showMenu);
  }

  logout(): void {
    this._cookieService.deleteAll();
    this.loginService.logout();
    this.storageService.clearFullStorage();
    localStorage.clear();
    // this.doLogin(); //dev
    // console.log("logout url==", `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`);
    window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
  }

  goToHome() {
    window.scroll(0, 0);
    let user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    ((window.location.href).includes('supplier')) ? this.router.navigate(['/web/supplier']): (user.roleId.indexOf(16) > -1) ? this.router.navigate(['/web/supplier']) : this.router.navigate(['/web/home']);
    // this.router.navigate(['/web/home']);
  }

  opendialog() {
    this.dataShare.setNotificationUpdate('true');
  }
  searchblock() {
    if (this.sblock == 'none') {
      this.sblock = "block"
    } else {
      this.sblock = "none"
    }

  }

  refreshSession() {
    sessionStorage.setItem("timeoutfun", "called")
    try {
      this.loader.show();
      let reftoken = this.loginService.getRefreshToken();
      this.loginService.getTokenFromRefreshToken(reftoken).subscribe(newToken => {
        // console.log("Updated reftoken", reftoken)
        // console.log("Updated token", newToken)
        newToken["refresh_token"] = reftoken;
        this._cookieService.delete('accessToken');
        this.loginService.authorizeForSessionRefresh(newToken).then(() => {
          this.loader.hide();
          sessionStorage.removeItem("timeoutfun");
          this.ngOnInit();
        })
      })
    } catch (err) {
      this.loader.hide();
      var loggedemail = JSON.parse(localStorage.getItem('sessionUserInfo'));
      const redirect = window.location.href;
      this.loginService.logout();
      this._cookieService.deleteAll();
      this.storageService.clearFullStorage();
      localStorage.clear();
      localStorage.setItem('requestedLogin', 'true');
      this.storageService.setInStorage('navigateUrl', redirect);
      localStorage.setItem('loggedemail', loggedemail.email);
      localStorage.setItem("getaccess", 'true');
      sessionStorage.removeItem("timeoutfun");
      window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
    }
  }

  logh() {
    sessionStorage.setItem("timeoutfun", "called")
    let myRawToken = this._cookieService.get('accessToken') !== null ? this._cookieService.get('accessToken') : undefined;
    var date1 = new Date(helper.getTokenExpirationDate(myRawToken));
    var date2 = new Date()
    var diff = Math.abs(date2.getTime() - date1.getTime()) / 3600000;
    // console.log("diff", diff)
    if (diff <= 2) {
      let refreshFlag = false;
      const dialogRef = this.dialog.open(SessionPopComponent, {
        width: '450px',
        disableClose: true,
        position: {
          top: '35px',
        },
      });

      let minutes = 1
      let seconds = 59
      this.dataShare.setMinutes(minutes);
      this.dataShare.setSeconds(seconds);
      let myInterval = setInterval(() => {
        seconds--;
        this.dataShare.setSeconds(seconds);
        // console.log('Time - ', minutes, ':', seconds);
        if (seconds == 0) {
          if (minutes <= 0 && seconds == 0) {
            // console.log('Timer hit 0');
            clearInterval(myInterval);
            //signout
            try {
              var loggedemail = JSON.parse(localStorage.getItem('sessionUserInfo'));
              const redirect = window.location.href;
              this.loginService.logout();
              this._cookieService.deleteAll();
              this.storageService.clearFullStorage();
              localStorage.clear();
              localStorage.setItem('requestedLogin', 'true');
              this.storageService.setInStorage('navigateUrl', redirect);
              localStorage.setItem('loggedemail', loggedemail.email);
              localStorage.setItem("getaccess", 'true');
            } finally {
              sessionStorage.removeItem("timeoutfun");
              window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
              // console.log('Logged out when timer hit 0');
            }
            //end signout
          } else {
            minutes--;
            this.dataShare.setMinutes(minutes);
            seconds = 60;
            this.dataShare.setSeconds(seconds);
          }
        }
      }, 1000);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);
        if (result.action == 'continue') {
          clearInterval(myInterval);
          this.loader.show();
          refreshFlag = true;
          let reftoken = this.loginService.getRefreshToken();
          this.loginService.getTokenFromRefreshToken(reftoken).subscribe(newToken => {
            // console.log("Updated reftoken", reftoken)
            // console.log("Updated token", newToken)
            newToken["refresh_token"] = reftoken;
            // console.log("hi0")
            this._cookieService.delete('accessToken');
            this.loginService.authorizeForSessionRefresh(newToken).then(() => {
              this.loader.hide();
              sessionStorage.removeItem("timeoutfun");
              this.ngOnInit();
            })
          })
        } else {
          // console.log('Clicked Signout within timer');
          clearInterval(myInterval);
          refreshFlag = true;
          var loggedemail = JSON.parse(localStorage.getItem('sessionUserInfo'));
          const redirect = window.location.href;
          this.loginService.logout();
          this._cookieService.deleteAll();
          this.storageService.clearFullStorage();
          localStorage.clear();
          localStorage.setItem('requestedLogin', 'true');
          this.storageService.setInStorage('navigateUrl', redirect);
          localStorage.setItem('loggedemail', loggedemail.email);
          localStorage.setItem("getaccess", 'true');
          sessionStorage.removeItem("timeoutfun");
          window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
          // console.log('Logged out from Signout btn');
        }
      });
    } else {
      // console.log("tiggered exp")
      this._cookieService.deleteAll('/')
      this.router.navigateByUrl('web/home');
      window.location.reload();
    }



  }

  globalsearch() {
    console.log('globalsearch method')
    localStorage.removeItem("selectedObj");
    if (this.globalsearchtxt.trim() == "") {
      this.isGlobalSearch = false;
      this.dataShare.doGlobalSearchTrigger();
      // this.router.navigateByUrl(localStorage.getItem("globalSearchReturnUrl"));
    } else {
      if (!this.router.url.includes("/search")) {
        localStorage.setItem("globalSearchReturnUrl", this.router.url)
      }

      this.isGlobalSearch = true;
      localStorage.setItem("globalsearchtxt", this.globalsearchtxt.trim());
      this.router.navigateByUrl('/web/search');
      this.dataShare.doGlobalSearchTrigger();
    }
  }
  clearsearch() {
    this.isGlobalSearch = false;
    this.globalsearchtxt = "";
    localStorage.removeItem("globalsearchtxt");
    //this.router.navigateByUrl(localStorage.getItem("globalSearchReturnUrl"));
    this.sblock = 'none';
    this.dataShare.doGlobalSearchTrigger();
  }
  checkem(txt) {
    this.globalsearchtxt = txt;
    if (this.globalsearchtxt == "") {
      // this.router.navigateByUrl(localStorage.getItem("globalSearchReturnUrl"));
      localStorage.removeItem("globalsearchtxt");
      this.isGlobalSearch = false;
      this.sblock = 'none';
    }
  }

  redirectallapi() {
    this.clearcatelogfilter();
    // console.log("called..../web/api/api-list")
    this.stateMgmtService.setState(null);
    this.router.navigateByUrl('/web/api/api-list');
  }

  clearcatelogfilter() {
    this.stateMgmtService.setClearState();
  }

  switchTheme(selected) {
    this.onCloseHamMenu();
    if (selected == 'default') {
      if (this.isLoggedIn) {
        this.callThemeAPI('default-theme');
      }
      this.dataShare.setTheme('default-theme');
    } else if (selected == 'light') {
      if (this.isLoggedIn) {
        this.callThemeAPI('light-theme');
      }
      this.dataShare.setTheme('light-theme');
    } else if (selected == 'dark') {
      if (this.isLoggedIn) {
        this.callThemeAPI('dark-theme');
      }
      this.dataShare.setTheme('dark-theme');
    }
  }


  callThemeAPI(val) {

    let obj = {
      "wwid": this.user['tokenUserInfo']['nickname'],
      "obj_list": [{
        "id": "DefaultTheme",
        "name": "Default Theme",
        "type": "Appearance",
        "value": val
      }],
      "action": "update",
    }

    //this.loader.show();
    this.serv.getPreferences(obj).subscribe((res) => {
      // if (res.message.indexOf("success") > -1)
      //this.handleGet(res);
    }).add(() => {
      //this.loader.hide();
    });
  }



  getPreferences() {
    let obj = {
      "action": "get_data",
      "user_id": this.user['tokenUserInfo']['nickname']
    }

    // this.loader.show();

    //get preference values of logged user from backend
    this.serv.getPreferences(obj).subscribe((res) => {
      let data = res['preference_list'].find(res => res.preference_key == "DefaultTheme");
      // console.log("data...", data)
      if (data) {
        this.dataShare.setTheme(data.preference_value);
      }

    }).add(() => {
      this.loader.hide();
    });
  }


}
