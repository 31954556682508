import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DataShareService } from './core/services/data-share.service';
import { LoginService } from './account/http/login.service';
import { Router, NavigationStart } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PromptUpdateService } from '../app/core/services/prompt-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'API Portal';
  currentTheme: string;
  isLoggedIn = false;
  userName: string;
  userRole: string;
  userGroup: string = "";
  user;
  isLoggedIn1: string;
  details: any = [];
  initials;
  combinedRole = "";
  constructor(private dataShare: DataShareService, private overlayContainer: OverlayContainer,
    private loginService: LoginService, private router: Router, private promt: PromptUpdateService,
    public deviceService: DeviceDetectorService, 
    ) {
    dataShare.getTheme().subscribe(theme => {
      this.currentTheme = theme;
      overlayContainer.getContainerElement().classList.remove('light-theme');
      overlayContainer.getContainerElement().classList.remove('dark-theme');
      overlayContainer.getContainerElement().classList.add(theme);
    })

  }

  public ngOnInit() {
    //  this.intCMSData();

    const url = window.location.href;
    this.promt.checkUpdate();
    console.log("this.deviceService.getDeviceInfo()",this.deviceService.getDeviceInfo())
    // console.log(parseFloat(this.deviceService.getDeviceInfo().browser_version))
    //safari && parseFloat(this.deviceService.getDeviceInfo().browser_version) >= 15
    if ((this.deviceService.getDeviceInfo().browser == 'Chrome' && parseFloat(this.deviceService.getDeviceInfo().browser_version) >= 97) ||
      (this.deviceService.getDeviceInfo().browser == 'Firefox' && parseFloat(this.deviceService.getDeviceInfo().browser_version) >= 91) ||
      (this.deviceService.getDeviceInfo().browser == 'Safari') ||
      (this.deviceService.getDeviceInfo().browser == 'MS-Edge-Chromium' && parseFloat(this.deviceService.getDeviceInfo().browser_version) >= 97)
      )
      {
     console.log("Supported Browser")
    }else{
      this.router.navigate(['/browser-not-supported']);
      return;
    }
    console.log("url data", url)
    const codeIdx = url.indexOf('code=');
    if (codeIdx !== -1) {
      console.log("constructor login")
      const fragment = url.substr(codeIdx);
      const fragmentsMap = this.extractKeyValueBasedOnDelimiter(fragment);
      // tslint:disable-next-line: no-string-literal
      const code = fragmentsMap['code'];
      this.loginService.fetchTokensForCode(code).subscribe({
        next: (tokens) => {
          console.log("nnnnnnnnnn,,,,,,,,,,,")
          this.loginService.authorize(tokens);
        },
        error: (err) => {
          console.log("nnnnnnnnnn,,,,,,,,,,,e")
          this.router.navigate(['/unauthorized']);
        }
      });
    }
    else {
   //console.log("window location",window.location.href)
   if(!window.location.href.includes("/home"))
   {
           // Root Url or Home Url    
 if (url.indexOf('home') > -1) {
  this.router.navigate(['/web/home']);
  // window.location.href = '/web/home'
}
else if (location.pathname == '/') {
  // this.router.navigate(['/web/home']);
  window.location.href = '/web/home'
}
   }
    }


    this.isLoggedIn = this.loginService.isAuthenticated();
    if (this.isLoggedIn) {
      //get user name from session storage
      
      let rawUser = localStorage.getItem("sessionUserInfo");
      this.user = JSON.parse(rawUser);
      console.log("after login data",this.user )
      this.userName = this.user.tokenUserInfo.givenName + " " + this.user.tokenUserInfo.familyName;
      this.initials = this.user.tokenUserInfo.givenName[0] + this.user.tokenUserInfo.familyName[0];

      if (this.user) {
        let us = JSON.parse(localStorage.getItem("user_det"));
        let finalUser = JSON.parse(localStorage.getItem("sessionUserInfo"));
        this.userName = this.user.tokenUserInfo.givenName + " " + this.user.tokenUserInfo.familyName;
        this.initials = this.user.tokenUserInfo.givenName[0] + this.user.tokenUserInfo.familyName[0];

        this.userGroup = localStorage.getItem('userGroup');

        finalUser.roleId = []; finalUser.role = []
        finalUser['id'] = us['logged_in_user_id'];
        us?.role_details?.forEach(role => {
          finalUser.roleId.push(role.role_id);
          finalUser.role.push(role.role_name);
        });
        this.userRole = finalUser.role;
        finalUser.name = us.first_name + ' ' + us.last_name;
        finalUser.email = us.email_id;
        localStorage.setItem("sessionUserInfo", JSON.stringify(finalUser));
        localStorage.setItem('user_id', us["logged_in_user_id"]);
        localStorage.setItem('user_name', us.name);
  
      }

    }

   
    
  } //end ngoninit

  ngDoCheck() {
   
  }




  extractKeyValueBasedOnDelimiter = (str, delimiter = '&') => {
    const map = {};
    if (str && str != null) {
      const fragArray = str.split(delimiter);
      for (const frag of fragArray) {
        const arr = frag.split('=');
        map[arr[0]] = arr[1];
      }
    }
    return map;
  }


}
